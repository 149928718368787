.container {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
}

.link {
  margin-right: 3px;
  font-weight: 200;
  text-decoration: none;
  color: black;
  opacity: 0.8;

  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 1;
  }
}
.selected {
    text-decoration: underline;
}

.insta {
  transform: translateY(3px);
  opacity: 0.8;

  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 1;
  }
}
