.question {
    font-weight: 800;
}

.answer {
    padding-left: 15px;
    font-weight: 300;
}

.container {
    padding: 0 40px;
    max-width: 800px;
    margin-bottom: 40px;
}

.line {
    margin-bottom: 10px;
}

.contact {
    margin-top: 20px;
    text-align: center;
}

