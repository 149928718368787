.text {
  font-size: medium;
  margin-bottom: 20px;
}

.container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.link {
  font-size: medium !important;
  text-decoration: underline !important;
  &:hover {
    opacity: 0.7;
  }
}

.spacer {
  height: 30px;
}

.fade {
  transform: scale(1);
  animation: disappear 1s forwards;
}

@keyframes disappear {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
    height: 0;
    margin-bottom: 0 !important;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  max-width: 800px;
  justify-content: space-around;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 20px;
}
