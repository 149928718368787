.App {
  display: flex;
  flex-direction: column;
  min-height: 92vh;
  /* height: 100vh; */
  width: 100vw;
  /* position: relative; */

  font-family: "Karla", serif;
}

.thank-you-image {
  background-image: url("./images/thankyou-min.png");
  background-size: cover;

  width: 60vw;
  height: 50vw;
  max-width: 600px;
  max-height: 500px;
}

.theme-2-image {
  background-image: url("./images/table-of-4.png");
  background-size: cover;

  width: 60vw;
  height: 50vw;
  max-width: 600px;
  max-height: 500px;
}

.default-image {
  background-image: url("./images/table-of-8.png");
  background-size: cover;

  width: 70vw;
  height: 50vw;
  max-width: 700px;
  max-height: 500px;
  margin-bottom: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 80%;
  width: 100%;
}

.App-header {
  margin-top: 30px;
  width: 100%;
  text-align: center;
  min-height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
