.container {
  /* display: flex;
    width: 100%;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px; */
  display: flex;
  flex-direction: column;

  justify-content: center;
  margin-bottom: 15px;
  transform: scale(0);
  animation: appear 1s forwards;
  /* margin-top: -10px; */
}

@keyframes appear {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  justify-content: space-around;
  /* padding-left: 20px;
  padding-right: 20px; */
  margin-bottom: 10px;
}

.list {
  font-size: medium;
  line-height: 1.3;
  max-width: 500px;
}

.listItem {
  padding-bottom: 8px;
  line-height: 1.3;
}

.preAmble {
  font-size: medium;
}

.disclaimer {
  padding: 30px;
}
