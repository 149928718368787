.signup-container {
  width: 80%;
  max-width: 600px;
  margin-bottom: 30px;
}

.signup-form {
    /* height: 400px; */
}


.button-container {
  margin-bottom: 40px;

}