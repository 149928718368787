.container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.text {
  font-size:xx-large;
  padding: 10px;

  @media (max-width: 800px) {
    /* … */
    font-size:x-large;
    padding: 5px;
  }
}

.link {
  text-decoration: none;
  color: black;
  position: relative;
}

.seattle {
  position: absolute;
  right: 8px;
  top: 30px;
  font-size: medium;
  @media (max-width: 800px) {
    /* … */
    font-size: small;
    right: 3px;
    top: 22px;
  }
}
